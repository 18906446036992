import { ERROR_IFRAME_SOURCE_CHAR_LIMIT_EXCEEDED } from './constants';

type AvailableSite = {
	avatarUrl: string;
	cloudId: string;
	displayName: string;
	isVertigo: boolean;
	products: AvailableSitesProductType[];
	url: string;
};

export type AvailableSitesResponse = {
	sites: AvailableSite[];
};
type AvailableSitesProductType =
	| 'jira-core.ondemand'
	| 'jira-incident-manager.ondemand'
	| 'jira-product-discovery'
	| 'jira-servicedesk.ondemand'
	| 'jira-software.ondemand';

const LIGHT_MODE = 'light' as const;
const DARK_MODE = 'dark' as const;
const AUTO = 'auto' as const;
export type ThemeMode = typeof DARK_MODE | typeof LIGHT_MODE | typeof AUTO | undefined;

// Server side cannot handle a url over 2048 characters
export const MAX_CHARACTERS_PER_URL = 2048;

export type GicConfigurationProps = {
	/**
	 * Keeps only the required fields in the GIC embed
	 */
	displayOnlyRequiredFields?: boolean;
	/**
	 * removes project selector
	 */
	disableProjectDropdown?: boolean;
	/**
	 * removes site selector
	 * @default true
	 */
	displaySitePicker?: boolean;
};

// Adapted from function of the same name from `@atlassian/link-create-jira`
// This gets the Issue Create embed URL for the given domain name and params
export const getIframeSrc = ({
	colorMode = AUTO,
	domainName = '',
	params,
	onFailure,
	displaySitePicker,
	disableProjectDropdown,
	displayOnlyRequiredFields,
}: {
	colorMode?: ThemeMode;
	domainName?: string;
	params?: URLSearchParams;
	onFailure?: (error: unknown) => void;
} & GicConfigurationProps): string => {
	const iframeParams = new URLSearchParams(params);
	iframeParams.append('postMessageInit', '1');

	if (displaySitePicker === false) {
		iframeParams.append('displaySitePicker', 'false');
	}
	if (disableProjectDropdown) {
		iframeParams.append('disableProjectDropdown', 'true');
	}
	if (displayOnlyRequiredFields) {
		iframeParams.append('displayOnlyRequiredFields', 'true');
	}
	if (colorMode) {
		iframeParams.append('themeMode', colorMode);
	}
	/**
	 * We want to use the postMessageInit param to instruct the Jira embed iframe
	 * to wait before mounting Issue Create.
	 * It will send a message back to us when it's ready to receive the init message
	 */
	const srcUrl = `${domainName}/jira/issues/create/embed?${iframeParams}`;

	if (srcUrl.length > MAX_CHARACTERS_PER_URL) {
		// Will need to handle this case properly in the future, only logging a warning for now
		if (process.env.NODE_ENV !== 'production') {
			/* eslint-disable-next-line no-console */
			console.warn(ERROR_IFRAME_SOURCE_CHAR_LIMIT_EXCEEDED);
		}
		onFailure?.(new Error(ERROR_IFRAME_SOURCE_CHAR_LIMIT_EXCEEDED));
	}

	return srcUrl;
};

export const JIRA_PRODUCTS: AvailableSitesProductType[] = [
	'jira-software.ondemand',
	'jira-core.ondemand',
	'jira-servicedesk.ondemand',
	'jira-product-discovery',
	// 'jira-incident-manager.ondemand',
];

export async function getAvailableJiraSites(): Promise<AvailableSitesResponse> {
	const requestConfig = {
		method: 'POST',
		credentials: 'include' as RequestCredentials,
		headers: {
			Accept: 'application/json',
			'Cache-Control': 'no-cache',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			products: JIRA_PRODUCTS,
		}),
	};

	const response = await fetch('/gateway/api/available-sites', requestConfig);
	if (response.ok) {
		return response.json();
	}
	throw response;
}
